import axios from "axios"
import SOTRE from "../store/index"

axios.defaults.baseURL = 'https://lf.fxysy.top:21002'
// axios.defaults.baseURL = "http://localhost:53535"

axios.interceptors.request.use(
  (config) => {
    // 默认请求时显示loading
    if (
      config?.headers?.loading === undefined ||
      config?.headers?.loading === true
    ) {
      SOTRE.dispatch("openLoading")
    }

    // 返回拦截器配置
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  (response) => {
    if (
      response?.config?.headers?.loading === true ||
      response?.config?.headers?.loading === undefined
    ) {
      SOTRE.dispatch("shutLoading")
    }
    return response
  },
  // axiosRetryInterceptor
  () => {
    SOTRE.dispatch("shutLoading")
  }
)

// 设置请求超时时间
axios.defaults.timeout = 60000

export default axios
