
import Main from "./components/home-main.vue"
import { defineComponent, computed, toRefs, reactive, onBeforeMount } from "vue"
import STORE from "./store/index"
import Loading from "./components/chiDouDou.vue"
import { ElMessage, ElMessageBox } from "element-plus"

interface DataProps {
  tokenCheckSuccess: boolean
}

export default defineComponent({
  name: "App",
  components: {
    Main,
    Loading,
  },
  setup() {
    const computedLoading = computed(() => {
      return STORE.state.loading
    })

    // 定义data
    const data: DataProps = reactive({
      // 令牌检查结果
      tokenCheckSuccess: false,
    })

    // 定义方法
    const methods = reactive({
      /**
       * @description: 输入令牌的方法
       * @return void
       */
      inputKey: () => {
        ElMessageBox.prompt("请输入密钥", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(({ value }) => {
          methods.getCheckResult(window.btoa(value), "manual")
        })
      },
      /**
       * @description: 请求接口验证令牌
       * @param token 输入的令牌
       * @param getType 验证的方式
       * @return void
       */
      getCheckResult: async (token: string, getType: string) => {
        if (token === "TGlubjUzNTY=") {
          data.tokenCheckSuccess = true
          localStorage.setItem("naviagtionToken", token)
          // 如果不是自动调用的时候就提示信息
          if (getType !== "auto") {
            ElMessage({
              message: "验证成功!",
              type: "success",
              duration: 1000,
            })
          }
        } else {
          data.tokenCheckSuccess = false
          // 如果不是自动调用的时候就提示信息
          if (getType !== "auto") {
            ElMessage({ message: "验证失败!", type: "error", duration: 1000 })
          }
        }
      },
    })

    // 生命周期 ==> beforeMount
    onBeforeMount(() => {
      let str = localStorage.getItem("naviagtionToken")
      str && str !== "" && methods.getCheckResult(str, "auto")
    })

    const refData = toRefs(data)
    const refMethod = toRefs(methods) // 解构data，使在dom中可以正常使用data的响应式data
    return {
      ...refData,
      ...refMethod,
      computedLoading,
    }
  },
})
