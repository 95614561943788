import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "appContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Main = _resolveComponent("Main")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tokenCheckSuccess)
      ? (_openBlock(), _createBlock(_component_Main, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.computedLoading > 0)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 1 }))
      : _createCommentVNode("", true),
    (!_ctx.tokenCheckSuccess)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "key",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputKey && _ctx.inputKey(...args)))
        }, "令"))
      : _createCommentVNode("", true)
  ]))
}