import { createApp } from "vue"
import App from "./App.vue"
import UTILS from "xe-utils"
import "element-plus/dist/index.css"
import STORE from "./store/index"
// 按需引入
import {
  ElConfigProvider,
  ElButton,
  ElRow,
  ElCol,
  ElMessageBox,
  ElMessage,
  ElDialog,
  ElIcon,
  ElDrawer,
  ElInput,
  ElSelect,
  ElOption,
  ElCollapse,
  ElCollapseItem,
  ElTooltip,
  ElTable,
  ElTableColumn,
  ElForm,
  ElFormItem,
  ElCalendar,
  ElCard,
  ElSwitch
} from "element-plus"

// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from "@element-plus/icons-vue"

import "@imengyu/vue3-context-menu/lib/vue3-context-menu.css"

const app = createApp(App)

// vuex
app.use(STORE)

// 全局注册
app.component("ElConfigProvider", ElConfigProvider) // 用于配置中文
app.component(ElButton.name, ElButton)
app.component(ElRow.name, ElRow)
app.component(ElCol.name, ElCol)
app.component(ElDrawer.name, ElDrawer)
app.component(ElSelect.name, ElSelect)
app.component(ElOption.name, ElOption)
app.component(ElInput.name, ElInput)
app.component(ElDialog.name, ElDialog)
app.component(ElCollapse.name, ElCollapse)
app.component(ElTooltip.name, ElTooltip)
app.component(ElCollapseItem.name, ElCollapseItem)
app.component(ElIcon.name, ElIcon)
app.component(ElTable.name, ElTable)
app.component(ElTableColumn.name, ElTableColumn)
app.component(ElForm.name, ElForm)
app.component(ElFormItem.name, ElFormItem)
app.component(ElCalendar.name, ElCalendar)
app.component(ElCard.name, ElCard)
app.component(ElSwitch.name, ElSwitch)

// icon
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 全局属性 ==> 即vue2中的vue.property()
app.config.globalProperties.$messageBox = ElMessageBox
app.config.globalProperties.$message = ElMessage
app.config.globalProperties.$utils = UTILS

app.mount("#app")
