import { createStore } from 'vuex'
interface stateProps {
  loading: number;
}

// 创建一个新的 store 实例
export default createStore({
  state() {
    return {
      loading: 0
    }
  },
  mutations: {
    set_loadAdd(state: stateProps) {
      state.loading++
    },
    set_loadSub(state: stateProps) {
      state.loading--
    },
    set_loadClear(state: stateProps) {
      state.loading = 0
    }
  },
  actions: {
    openLoading(context) {
      context.commit('set_loadAdd')
    },
    shutLoading(context) {
      context.commit('set_loadSub')
    },
    clearLoading(context) {
      context.commit('set_loadClear')
    },
  },
})
